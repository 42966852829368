// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/template/NotFound.css */

.notfound-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: radial-gradient(circle, #2a2a72, #009ffd);
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 20px;
}

.notfound-animation {
  margin-bottom: 20px;
}

.notfound-svg {
  width: 200px;
  height: 100px;
}

.notfound-text {
  font-size: 120px;
  font-weight: bold;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-dasharray: 750;
  stroke-dashoffset: 750;
  animation: dash 2s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.notfound-title {
  font-size: 2.5rem;
  margin: 10px 0;
}

.notfound-description {
  font-size: 1.2rem;
  margin: 0 0 20px;
  max-width: 400px;
}

.notfound-button {
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.notfound-button:hover {
  background-color: #ee5253;
  transform: scale(1.05);
}
`, "",{"version":3,"sources":["webpack://./src/template/NotFound.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,qDAAqD;EACrD,cAAc;EACd,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,UAAU;EACV,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,iDAAiD;AACnD;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB","sourcesContent":["/* src/template/NotFound.css */\n\n.notfound-wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  background: radial-gradient(circle, #2a2a72, #009ffd);\n  color: #ffffff;\n  font-family: \"Poppins\", sans-serif;\n  text-align: center;\n  padding: 20px;\n}\n\n.notfound-animation {\n  margin-bottom: 20px;\n}\n\n.notfound-svg {\n  width: 200px;\n  height: 100px;\n}\n\n.notfound-text {\n  font-size: 120px;\n  font-weight: bold;\n  fill: none;\n  stroke: #ffffff;\n  stroke-width: 2;\n  stroke-dasharray: 750;\n  stroke-dashoffset: 750;\n  animation: dash 2s linear forwards;\n}\n\n@keyframes dash {\n  to {\n    stroke-dashoffset: 0;\n  }\n}\n\n.notfound-title {\n  font-size: 2.5rem;\n  margin: 10px 0;\n}\n\n.notfound-description {\n  font-size: 1.2rem;\n  margin: 0 0 20px;\n  max-width: 400px;\n}\n\n.notfound-button {\n  background-color: #ff6b6b;\n  color: #ffffff;\n  border: none;\n  padding: 10px 20px;\n  font-size: 1rem;\n  font-weight: bold;\n  border-radius: 25px;\n  cursor: pointer;\n  transition: background-color 0.3s, transform 0.2s;\n}\n\n.notfound-button:hover {\n  background-color: #ee5253;\n  transform: scale(1.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
