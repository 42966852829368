import React from "react";

const TurmsAndCondition = () => {
  return (
    <>
      <div className="m-5 p-5">
        <h1>Turms and conditions</h1>
      </div>
    </>
  );
};

export default TurmsAndCondition;
