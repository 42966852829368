// ContactUsContext.js
import React, { createContext, useState, useContext } from "react";
import { sendContactForm } from "../apiServices/ContactUsServices";

const ContactUsContext = createContext();

export const useContactUs = () => {
  return useContext(ContactUsContext);
};

export const ContactUsProvider = ({ children }) => {
  const [formStatus, setFormStatus] = useState({
    warning: "",
    success: "",
  });

  const sendForm = async (formData) => {
    setFormStatus({ warning: "", success: "" });

    try {
      const data = await sendContactForm(formData);
      setFormStatus({ success: "Your message was sent, thank you!" });
      return data;
    } catch (error) {
      setFormStatus({ warning: error.message });
    }
  };

  return (
    <ContactUsContext.Provider value={{ sendForm, formStatus }}>
      {children}
    </ContactUsContext.Provider>
  );
};
