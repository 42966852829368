import React, { createContext, useState, useEffect } from "react";
import { deleteAddressOnServer, fetchAddressData, addAddress, updateAddress } from "../apiServices/addressServices";

export const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const token = localStorage.getItem("token");

  // Fetch addresses and other data, updating state
  const fetchAddresses = async () => {
    if (token) {
      try {
        const response = await fetchAddressData(token);

        if (Array.isArray(response.addresses)) {
          setAddresses(response.addresses);
        } else {
          console.error("Expected an array of addresses but got:", response.addresses);
        }

        if (Array.isArray(response.paymentGateways)) {
          setPaymentGateways(response.paymentGateways);
        } else {
          console.error("Expected an array of payment gateways but got:", response.paymentGateways);
        }

        if (Array.isArray(response.shippingMethods)) {
          setShippingMethods(response.shippingMethods);
        } else {
          console.error("Expected an array of shipping methods but got:", response.shippingMethods);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    }
  };

  const getAddress = async () => {
    fetchAddresses();
  }

  // Add a new address and ensure only one default address
  const handleAddAddress = async (addressData) => {
    if (token) {
      try {
        // Check if the new address is marked as default
        if (addressData.defaultaddress) {
          // Update existing addresses to remove default setting
          const updatedAddresses = await Promise.all(
            addresses.map((address) =>
              address.defaultaddress
                ? updateAddress(token, address.id, { ...address, defaultaddress: 0 })
                : address
            )
          );
          // Update local state to reflect removed default tags
          setAddresses(updatedAddresses);
        }

        // Add the new address
        const newAddress = await addAddress(token, addressData);
        await fetchAddresses();  // Refetch updated list of addresses

      } catch (error) {
        console.error("Error adding address:", error);
      }
    }
  };

  // Update an existing address and update the state
  const handleUpdateAddress = async (addressId, addressData) => {
    if (token) {
      try {
        const updatedAddress = await updateAddress(token, addressId, addressData);
        setAddresses((prevAddresses) =>
          prevAddresses.map((address) =>
            address.id === addressId ? updatedAddress : address
          )
        );
      } catch (error) {
        console.error("Error updating address:", error);
      }
    }
  };

  // Delete an address and update the state
  const handleDeleteAddress = async (addressId) => {
    if (token) {
      try {
        await deleteAddressOnServer(token, addressId);
        setAddresses((prevAddresses) =>
          prevAddresses.filter((address) => address.id !== addressId)
        );
      } catch (error) {
        console.error("Error deleting address:", error);
      }
    }
  };

  const value = {
    addresses,
    selectedAddressId,
    setSelectedAddressId,
    handleDeleteAddress,
    handleAddAddress,
    handleUpdateAddress,
    fetchAddresses,
    paymentGateways,
    shippingMethods,
    getAddress
  };

  return (
    <AddressContext.Provider value={value}>
      {children}
    </AddressContext.Provider>
  );
};
