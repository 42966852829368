// src/services/productService.js
import axios from 'axios';
import { API_ENDPOINTS } from '../ApiEndPoints';

const productUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PRODUCTS}`;

// Function to fetch products
export const fetchProducts = async () => {
  try {
    const response = await axios.get(productUrl, {
      headers: {
        'Content-Type': 'application/json',
        // Uncomment and set token if needed for authorization
        // 'Authorization': `Bearer ${token}`,
      },
    });
    return response.data.product; // Return product data on success
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error.response?.data?.message || "Failed to load products"; // Return error message
  }
};
