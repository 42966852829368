import { API_ENDPOINTS } from "../ApiEndPoints";

const placeOrderUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PLACE_ORDER}`;
const showMyOrdersUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_MY_ORDER}`;
const showOrderHistoryUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_ORDER_HISTORY}`;
const cancleMyOrderUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.CANCLE_ORDER}`;
const repeatOrderUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REPEAT_ORDER}`;

const token = localStorage.getItem("token");

// Function to place an order
export const placeOrder = async ({
  shippingMethod,
  comment,
  addressId,
  payment,
}) => {
  const orderData = {
    shippingMethod,
    comment,
    addressId,
    payment,
  };

  try {
    const response = await fetch(placeOrderUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      throw new Error(`Failed to place order: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error placing order:", error);
    throw error;
  }
};

// Function to show my orders
export const showMyOrders = async (language) => {
  try {
    // Construct the URL with query parameters
    const url = new URL(showMyOrdersUrl);
    url.searchParams.append("language", language);

    // Fetch the data with the constructed URL
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch orders: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

// Function to show my orders
export const showOrderHistory = async (language) => {
  try {
    // Construct the URL with query parameters
    const url = new URL(showOrderHistoryUrl);
    if (language) {
      url.searchParams.append("language", language);
    }

    // Fetch the data with the constructed URL
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch orders: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

// Function to cancel an order
export const cancelOrder = async (orderId) => {
  try {
    const response = await fetch(`${cancleMyOrderUrl}/${orderId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to cancel order: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error canceling order:", error);
    throw error;
  }
};

// Function to repeat a order
export const repeatOrder = async (orderId) => {
  try {
    const response = await fetch(`${repeatOrderUrl}/${orderId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to Re-order the item: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error Repeating Order:", error);
    throw error;
  }
};
