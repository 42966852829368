import React, { createContext, useContext, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  fetchLikedProductsFromServer,
  addLikedProduct,
  removeLikedProduct,
} from "../apiServices/likedProductService";

export const LikedProductContext = createContext();

export const LikedProductProvider = ({ children }) => {
  const [likedItems, setLikedItems] = useState(() => {
    const savedLikedItems = localStorage.getItem("likedItems");
    return savedLikedItems ? JSON.parse(savedLikedItems) : [];
  });
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem("token");

  // Sanitize product data to avoid circular references
  const sanitizeProduct = (product) => ({
    productId: product.productId,
    name: typeof product.name === "string" ? product.name.replace(/<\/?[^>]+(>|$)/g, "") : "",
    description:
      typeof product.description === "string" ? product.description.replace(/<\/?[^>]+(>|$)/g, "") : "",
    product: {
      name: typeof product.product?.name === "string" ? product.product.name.replace(/<\/?[^>]+(>|$)/g, "") : "",
      price: product.product?.price || 0,
      description:
        typeof product.product?.description === "string"
          ? product.product.description.replace(/<\/?[^>]+(>|$)/g, "")
          : "",
      image: product.product?.image || "",
    },
  });

  // Fetch liked items (from server or local storage)
  const fetchLikedItems = async () => {
    setIsLoading(true);

    if (!token) {
      const cachedLikedItems = localStorage.getItem("likedItems");
      setLikedItems(cachedLikedItems ? JSON.parse(cachedLikedItems) : []);
      setIsLoading(false);
      return;
    }

    try {
      const data = await fetchLikedProductsFromServer(token);
      const cleanData = data.wishList.map(sanitizeProduct);
      setLikedItems(cleanData);
      localStorage.setItem("likedItems", JSON.stringify(cleanData));
    } catch (error) {
      console.error("Error fetching liked items:", error);
      enqueueSnackbar("Failed to load liked products from server.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  // Sync local liked items to server after login
  const syncLikedItemsToDatabase = async () => {
    const storedLikedItems = JSON.parse(localStorage.getItem("likedItems") || "[]");
    if (!token || storedLikedItems.length === 0) return;

    for (const item of storedLikedItems) {
      try {
        await addLikedProduct(token, item.productId);
      } catch (err) {
        console.error("Error syncing liked items:", err);
      }
    }

    localStorage.removeItem("likedItems");
    await fetchLikedItems();
  };

  // Add product to liked items
  const addLikeProduct = async (product) => {
    const sanitizedProduct = sanitizeProduct(product);

    const existingItem = likedItems.some((item) => item.productId === sanitizedProduct.productId);
    if (existingItem) {
      enqueueSnackbar("Product is already in your favorites!", { variant: "info" });
      return;
    }

    const updatedLikedItems = [...likedItems, sanitizedProduct];
    setLikedItems(updatedLikedItems);
    localStorage.setItem("likedItems", JSON.stringify(updatedLikedItems));
    enqueueSnackbar("Product added to favorites!", { variant: "success" });

    if (token) {
      try {
        await addLikedProduct(token, sanitizedProduct.productId);
        enqueueSnackbar("Product added to favorites!", { variant: "success" });
      } catch (err) {
        console.error("Error adding product to favorites:", err);
        enqueueSnackbar("Failed to add product to server!", { variant: "error" });
      }
    }
  };

  // Remove product from liked items
  const removeFromFavorite = async (product) => {
    const updatedLikedItems = likedItems.filter((item) => item.productId !== product.productId);
    setLikedItems(updatedLikedItems);
    localStorage.setItem("likedItems", JSON.stringify(updatedLikedItems));
    enqueueSnackbar("Product removed from favorites!", { variant: "error" });

    if (token) {
      try {
        await removeLikedProduct(product.productId);
      } catch (err) {
        console.error("Error removing product from favorites:", err);
      }
    }
  };

  // Clear all liked items
  const clearLikedItems = () => {
    setLikedItems([]);
    localStorage.removeItem("likedItems");
  };

  // Sync likedItems with server after login
  useEffect(() => {
    if (token) {
      syncLikedItemsToDatabase();
    }
  }, [token]);

  // Sync likedItems with local storage on every update
  useEffect(() => {
    const cleanLikedItems = likedItems.map(sanitizeProduct); // Ensure sanitized data
    localStorage.setItem("likedItems", JSON.stringify(cleanLikedItems));
  }, [likedItems]);

  return (
    <LikedProductContext.Provider
      value={{
        likedItems,
        fetchLikedItems,
        addLikeProduct,
        removeFromFavorite,
        clearLikedItems,
        isLoading,
      }}
    >
      {children}
    </LikedProductContext.Provider>
  );
};
