// src/api/cartApi.js
import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const addToCartUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ADD_TO_CART}`;
const showCartUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_CART}`;
const removeFromCartUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REMOVE_FROM_CART}`;

// Fetch cart items from the server
export const fetchCartFromServer = async (language, token) => {
  try {
    const response = await axios.get(showCartUrl, {
      params: { language },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Axios automatically parses the response, so you can directly return the data
    return response.data;
  } catch (error) {
    console.error("Error fetching cart:", error.message);
    // Optionally, you can customize the error message or rethrow it
    throw error.response?.data?.message || "Failed to fetch cart";
  }
};

// Add item to the cart
export const addToCartOnServer = async (token, productId, quantity) => {
  try {
    await fetch(addToCartUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ productId, quantity }),
    });
  } catch (error) {
    console.error("Failed to add item to cart on server:", error);
    throw error;
  }
};

// Update quantity in the cart
export const updateQuantityInCartOnServer = async (
  token,
  productId,
  quantity
) => {
  try {
    await fetch(addToCartUrl, {
      method: "POST", // Adjust this method to PUT or PATCH as required by API
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ productId, quantity }),
    });
  } catch (error) {
    console.error("Failed to update item quantity on server:", error);
    throw error;
  }
};

// Remove item from the cart
export const removeFromCartOnServer = async (token, cartId) => {
  try {
    await fetch(`${removeFromCartUrl}/${cartId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Failed to remove item from server cart:", error);
    throw error;
  }
};
