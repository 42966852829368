import React, { createContext, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  fetchCartFromServer,
  addToCartOnServer,
  updateQuantityInCartOnServer,
  removeFromCartOnServer,
} from "../apiServices/cartApi";
import { useLanguage } from "./LanguageContext";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isCartLoaded, setIsCartLoaded] = useState(false);

  const token = localStorage.getItem("token");
  const { language } = useLanguage();

  const fetchCart = async () => {
    setIsLoading(true);

    if (!token) {
      const cachedCartItems = localStorage.getItem("cartItems");
      setCartItems(cachedCartItems ? JSON.parse(cachedCartItems) : []);
      setIsLoading(false);
      return;
    }

    try {
      const data = await fetchCartFromServer(language, token);
      setCartItems(data.cartItems);
      localStorage.setItem("cartItems", JSON.stringify(data.cartItems));
    } catch {
      const cachedCartItems = localStorage.getItem("cartItems");
      setCartItems(cachedCartItems ? JSON.parse(cachedCartItems) : []);
      enqueueSnackbar(
        "Failed to load cart from server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
    } finally {
      setIsLoading(false);
      setIsCartLoaded(true);
    }
  };

  const syncCartWithServer = async () => {
    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    if (token && localCartItems.length > 0) {
      for (const item of localCartItems) {
        try {
          await addToCartOnServer(token, item.productId, item.quantity);
        } catch {
          enqueueSnackbar("Failed to sync cart with server.", {
            variant: "error",
          });
        }
      }
      // Clear local storage after syncing
      localStorage.removeItem("cartItems");
      // Optionally, fetch the latest cart from the server after syncing
      fetchCart();
    }
  };

  useEffect(() => {
    if (token) {
      syncCartWithServer();
    }
  }, [language]);

  useEffect(() => {
    syncCartWithLocalStorage();
  }, [cartItems]);

  const cleanContent = (content) =>
    Array.isArray(content)
      ? content.map((part) => (typeof part === "string" ? part : "")).join("")
      : content;

  const syncCartWithLocalStorage = () => {
    const serializableCartItems = cartItems.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      productId: item.productId,
      product: {
        name: cleanContent(item.product.name),
        price: item.product.price,
        description: cleanContent(item.product.description),
        image: item.product.image,
      },
    }));
    localStorage.setItem("cartItems", JSON.stringify(serializableCartItems));
  };

  // const findCartItem = (productId) =>
  //   cartItems.find((cartItem) => cartItem.productId === productId);

  const addToCart = async (item, newQuantity = 1) => {
    // Transforming the `item` into the desired structure
    const cleanedItem = {
      id: Date.now(), // Generate a unique ID for the cart item
      quantity: newQuantity,
      productId: item.productId,
      product: {
        name: item.name,
        price: item.price,
        description: item.description,
        image: item.image, // Include only the image object
      },
    };

    setIsLoading(true);
    const existingItem = cartItems.find(
      (cartItem) => cartItem.productId === item.productId
    );

    let updatedCartItems;

    if (existingItem) {
      // Update quantity if the product is already in the cart
      updatedCartItems = cartItems.map((cartItem) =>
        cartItem.productId === existingItem.productId
          ? { ...cartItem, quantity: cartItem.quantity + newQuantity }
          : cartItem
      );
      enqueueSnackbar(`quantity updated in cart!`, {
        variant: "success",
      });
    } else {
      // Add new item to the cart
      updatedCartItems = [...cartItems, cleanedItem];
      enqueueSnackbar(`product added to cart!`, { variant: "success" });
    }

    // Update local storage and state
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    // Sync with the server if the user is logged in
    if (token) {
      try {
        await addToCartOnServer(token, item.productId, newQuantity);
      } catch {
        enqueueSnackbar("Failed to add item to cart on server!", {
          variant: "error",
        });
      }
    }

    setIsLoading(false);
  };

  const updateQuantityInCart = async (item, newQuantity) => {
    if (newQuantity <= 0) {
      enqueueSnackbar("Quantity cannot be zero or negative!", {
        variant: "error",
      });
      return;
    }

    // Update the local state of cart items
    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.productId === item.productId
        ? { ...cartItem, quantity: newQuantity }
        : cartItem
    );

    // Update the local state and localStorage immediately
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    // Show success notification
    enqueueSnackbar(`${item.name} quantity updated!`, { variant: "success" });

    // If the user is logged in, update the cart on the server as well
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await updateQuantityInCartOnServer(token, item.productId, newQuantity);
      } catch {
        enqueueSnackbar("Failed to update item quantity on server!", {
          variant: "error",
        });
      }
    }
  };

  const removeFromCart = async (itemToRemove) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemToRemove.id)
    );
    enqueueSnackbar(`${itemToRemove.product.name} removed from cart!`, {
      variant: "success",
    });

    try {
      await removeFromCartOnServer(token, itemToRemove.id);
    } catch {
      enqueueSnackbar("Failed to remove item from server cart!", {
        variant: "error",
      });
    }
  };

  const clearCart = () => {
    setCartItems([]); // Clear the cart state
    localStorage.removeItem("cartItems"); // Remove cart from localStorage
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantityInCart,
        isLoading,
        fetchCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
