import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner from react-spinners

const LanguageSwitcher = ({toggleDrawer}) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const changeLanguage = (lng, e) => {
    e.preventDefault();
    setLoading(true); // Show preloader

    // Simulate a short delay to display the preloader
    setTimeout(() => {
      i18n.changeLanguage(lng).then(() => {
        setLoading(false); // Hide preloader after language change
      });
    }, 2000); // Delay for 1 second
  };

  const handleDropdownEnglishClick = (e) => {
    changeLanguage("en", e);
    toggleDrawer();
  };

  const handleDropdownGermanClick = (e) => {
    changeLanguage("de", e);
    toggleDrawer();
  };

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <ClipLoader
            className=""
            color="#ffffff"
            loading={loading}
            size={60}
          />
        </div>
      )}

      <ul className="navbar-nav mb-2 mb-lg-0">
        <li className="nav-item dropdown">
          <a
            href="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Flag
              code={i18n.language === "en" ? "GB" : "DE"}
              style={{ width: "30px", marginRight: "8px" }}
            />
          </a>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="languageDropdown"
          >
            <li>
              <a
                href="#"
                className="dropdown-item"
                onClick={(e) => handleDropdownEnglishClick(e)}
              >
                <Flag code="GB" style={{ width: "20px", marginRight: "8px" }} />{" "}
                English
              </a>
            </li>
            <li>
              <a
                href="#"
                className="dropdown-item"
                onClick={(e) => handleDropdownGermanClick(e)}
              >
                <Flag code="DE" style={{ width: "20px", marginRight: "8px" }} />{" "}
                German
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
