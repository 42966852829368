import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./Context/AuthContext";
import { useSnackbar } from "notistack";
import LoginModel from "./template/models/LoginModel";
import RegisterModel from "./template/models/RegisterModel";

const ProtectedRoute = () => {
  const { isLoggedIn, loading } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false); // Prevent immediate navigation
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => setShowLoader(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  const handleRegisterClick = (e) => {
    e.preventDefault();
    setShowRegisterModal(true);
    setShowLoginModal(false);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    setShowLoginModal(true);
    setShowRegisterModal(false);
  };

  useEffect(() => {
    if (!loading && !isLoggedIn && !showLoader && !isRedirecting) {
      enqueueSnackbar("You need to log in to access this page", {
        variant: "info",
        autoHideDuration: 2000,
      });
      setShowLoginModal(true);
      setIsRedirecting(true); 
    }
  }, [isLoggedIn, loading, showLoader, isRedirecting, enqueueSnackbar]);

  const handleCloseModal = () => {
    setShowLoginModal(false);
    setShowRegisterModal(false);
    navigate("/", { state: { from: location } });
  };

  if (loading || showLoader) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      {isLoggedIn ? (
        <Outlet />
      ) : (
        <>
          {/* Modals are shown first */}
          <LoginModel
            handleRegisterClick={handleRegisterClick}
            showLoginModal={showLoginModal}
            handleCloseModal={handleCloseModal}
          />
          <RegisterModel
            handleLoginClick={handleLoginClick}
            showRegisterModal={showRegisterModal}
            handleCloseModal={handleCloseModal}
          />
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
