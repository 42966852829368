import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import { ContextProvider } from '../Context/Provider/ContextProvider';

function Template(props) {
  return (
    <>
    <ContextProvider>
      <Header />
      <Content>{props.children}</Content>
      <Footer />
      </ContextProvider>
    </>
  );
}

export default Template;
