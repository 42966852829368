import React, { createContext, useState, useContext } from "react";
import { placeOrder as placeOrderService } from "../apiServices/OrderService";
import { CartContext } from "./CartContext"; // Import CartContext

export const PlaceOrderContext = createContext();

const PlaceOrderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { clearCart } = useContext(CartContext);

  const placeOrder = async (orderDetails) => {
    setIsLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);
    try {
      const result = await placeOrderService(orderDetails);
      setSuccessMessage("Order placed successfully!");

      // Clear the cart after successful order
      clearCart();

      return result;
    } catch (error) {
      console.error("Error placing order:", error);
      setErrorMessage("Failed to place order. Please try again.");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const resetOrderStatus = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  return (
    <PlaceOrderContext.Provider
      value={{
        placeOrder,
        isLoading,
        successMessage,
        errorMessage,
        resetOrderStatus,
      }}
    >
      {children}
    </PlaceOrderContext.Provider>
  );
};

export default PlaceOrderProvider;
