import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios
import { useSnackbar } from "notistack"; // Import useSnackbar
import { ClipLoader } from "react-spinners";
import { API_ENDPOINTS } from "../../ApiEndPoints";

const RegisterModal = ({
  showRegisterModal,
  handleCloseModal,
  handleLoginClick,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar(); // Hook to show snackbar notifications
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState(""); // State for contact number
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle file selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file); // Set the selected file
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true immediately

    if (!termsAccepted) {
      alert('You must accept the terms and conditions to register.');
      return;
    }

    // Wait for 6 seconds before proceeding with the login request
    await new Promise((resolve) => setTimeout(resolve, 1500));
    const attachmentUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ATTACHMENTS}`;
    const registerUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REGISTER}`;

    // Step 1: Upload the profile image
    const imageFormData = new FormData();
    imageFormData.append("attachment", profileImage);

    try {
      const imageResponse = await axios.post(attachmentUrl, imageFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (imageResponse.status === 201) {
        const imageData = imageResponse.data[0]; // Extract the relevant data from the response
        const avatarId = imageData.id; // Replace with the actual property that contains the ID

        // Step 2: Register the user with the image ID
        const registrationData = {
          firstname,
          lastname,
          email,
          contact,
          password,
          avatar_id: avatarId, // Use the image ID from the upload response
        };

        const response = await axios.post(registerUrl, registrationData);

        if (response.status === 201) {
          enqueueSnackbar("Registration successful!", {
            variant: "success",
            autoHideDuration: 2000,
          });
          handleCloseModal();

          // Reset form fields
          setfirstname("");
          setlastName("");
          setEmail("");
          setContact("");
          setPassword("");
          setProfileImage(null);
        } else {
          enqueueSnackbar("Registration failed. Please try again.", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
        autoHideDuration: 2000,
      });
    } finally {
      setLoading(false); // Ensure loading is set to false after the process
    }
  };

  if (!showRegisterModal) return null;

  return (
    <>
      {/* Background Overlay */}
      <div className="overlay-custom" onClick={handleCloseModal}></div>
      <div
        className="modal fade show"
        tabIndex="-1"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg justify-content-center">
          <div className="modal-content text-start p-0">
            <div className="modal-header ">
              <h5 className="modal-title">{t("Create-an-Account")}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body row">
              <div className="col-md-12">
                <form onSubmit={handleRegisterSubmit}>
                  <div className="mb-3 d-flex">
                    <div className="col-6 pe-3">
                      <label htmlFor="name" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        // placeholder={t("Enter-your-Name")}
                        placeholder="Enter First Name"
                        value={firstname}
                        onChange={(e) => setfirstname(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="name" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        // placeholder={t("Enter-your-Name")}
                        placeholder="Enter Last Name"
                        value={lastname}
                        onChange={(e) => setlastName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex">
                    <div className="col-6 pe-3">
                      <label htmlFor="email" className="form-label">
                        {t("Email-address")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={t("Enter-your-email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="contact" className="form-label">
                        {t("Contact")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contact"
                        placeholder={t("Enter-your-contact-number")}
                        value={contact}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,10}$/.test(value)) {
                            setContact(value);
                          }
                        }}
                        pattern="\d{10}"
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="attachment" className="form-label">
                      {t("Profile Image")}
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="attachment"
                      accept="image/*"
                      onChange={handleImageChange}
                      required
                    />
                  </div>
                  <div className="mb-3 position-relative">
                    <label htmlFor="password" className="form-label">
                      {t("Password")}
                    </label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control"
                      id="password"
                      placeholder={t("Enter-your-Password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span
                      className="position-absolute top-70 end-0 translate-middle-y me-3"
                      style={{ cursor: "pointer" }}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-green-theme"
                    disabled={loading}
                  >
                    {loading ? (
                      <div style={{ width: "60px" }}>
                        <ClipLoader
                          color={"#000"}
                          loading={loading}
                          size={24}
                        />
                      </div>
                    ) : (
                      t("Register")
                    )}
                  </button>
                </form>

                {/* Login Link */}
                <div className="mt-3 text-center">
                  <p>
                    {t("Already a User?")}{" "}
                    <Link to="#" onClick={handleLoginClick}>
                      {t("Login")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterModal;
