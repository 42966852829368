import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute"; // Import ProtectedRoute
import TurmsAndCondition from "./template/FooterPages/TurmsAndCondition.js";
import NotFound from "./template/NotFound"; // Import NotFound component

// Lazy load each component
const ProductDetail = lazy(() => import("./products/detail/ProductDetail"));
const Landing = lazy(() => import("./landing/Landing"));
const ProductList = lazy(() => import("./products/ProductList"));
const CheckOut = lazy(() => import("./products/Cart/CheckOut.js"));
const CartPage = lazy(() => import("./products/Cart/CartPage.js"));
const LikedProducts = lazy(() => import("./template/User/LikedProducts.js"));
const ContactUs = lazy(() => import("./template/FooterPages/ContactUs"));
const Faqs = lazy(() => import("./template/FooterPages/Faqs"));
const AboutUs = lazy(() => import("./template/FooterPages/AboutUs"));
const UserProfile = lazy(() => import("./template/User/UserProfile.js"));

const AllRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {" "}
      {/* Show loading indicator */}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Landing />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/products/:productId" element={<ProductDetail />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/terms&condition" element={<TurmsAndCondition />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/liked-products" element={<LikedProducts />} />
          <Route path="/user-profile" element={<UserProfile />} />
        </Route>

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AllRoutes;
