// src/services/likedProductService.js
import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const token = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
};

const addLikedProductUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.LIKED_PRODUCT}`;
const showLikedProductUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_WISHLIST}`;
const removeLikedProductUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REMOVE_LIKED_PRODUCT}`;

export const fetchLikedProductsFromServer = async () => {
  try {
    const response = await fetch(showLikedProductUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error("Failed to fetch Liked Products");
    return await response.json();
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const addLikedProduct = async (token, productId) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      addLikedProductUrl,
      { productId },
      { headers }
    );
    return response.status === 201;
  } catch (error) {
    console.error("Error adding product to wishlist on server:", error.response || error);
    return false;
  }
};

export const removeLikedProduct = async (productId) => {
  const response = await axios.delete(`${removeLikedProductUrl}/${productId}`, {
    headers,
  });
  return response.status === 201;
};
