import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { CartContext } from "../Context/CartContext.js";
import { LikedProductContext } from "../Context/LikedProductContext.js";
import SiteLogo from "../SiteLogo.js";
import LanguageSwitcher from "../locales/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import LoginModel from "./models/LoginModel.js";
import RegisterModel from "./models/RegisterModel.js";
import { useAuth } from "../Context/AuthContext.js";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useCategory } from "../Context/CategoryContext";
import { useSearch } from "../Context/SearchContext.js";
import { event } from "jquery";

function Header() {
  const { t } = useTranslation();
  const { cartItems, clearCart } = useContext(CartContext);
  const { likedItems, clearLikedItems } = useContext(LikedProductContext);
  const { isLoggedIn, logout } = useAuth();
  const Navigate = useNavigate();
  const location = useLocation();
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const { handleCategoryClick } = useCategory();
  const { setSearchTerm } = useSearch();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("login") === "true") {
      setShowLoginModal(true);
    }
  }, [location]);

  const toggleDrawer = () => setOpenedDrawer(!openedDrawer);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevents form submission
    setSearchTerm(searchQuery); // Set search term in the context
    handleCategoryClick("All Products"); // Optional: Adjust this based on the default category behavior
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(event); // Trigger search when Enter key is pressed
    }
  };

  const handleLogout = () => {
    logout();

    // Clear the cart and Liked items after successful order
    clearCart();
    clearLikedItems();

    Navigate("/");
    toggleDrawer();
  };

  const handleRegisterClick = (e) => {
    e.preventDefault();
    setShowRegisterModal(true);
    setShowLoginModal(false);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    setShowLoginModal(true);
    setShowRegisterModal(false);
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value; // Get selected category
    handleCategoryClick(selectedCategory); // Update category context and scroll
  };

  const handleDropdownLoginClick = () => {
    setShowLoginModal(true);
    toggleDrawer();
  };

  const handleDropdownRegisterClick = () => {
    setShowRegisterModal(true);
    toggleDrawer();
  };

  return (
    <>
      <header className="w-auto overflow-hidden">
        <nav className="navbar fixed-top navbar-expand-lg scrolled navbar-light bg-white border-bottom">
          <div className="container-fluid">
            <div
              className={`navbar-collapse offcanvas-collapse justify-content-between ${
                openedDrawer ? "open" : ""
              }`}
            >
              <SiteLogo toggleDrawer={toggleDrawer} />
              <div className="search-bar-container me-3 d-none d-lg-flex">
                <select
                  className="category-select"
                  aria-label="Select Category"
                  onChange={handleCategoryChange}
                >
                  <option value="All">All</option>
                  <option value="Medicine">Medicine</option>
                  <option value="Dryfruits">Dry Fruits</option>
                  <option value="HealthInstruments">Health</option>
                </select>
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  aria-label="Search input"
                />
                <button
                  className="search-button"
                  onClick={handleSearchSubmit} // Trigger search on click
                  aria-label="Search button"
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
              <div className="d-flex">
                <div className="me-0  ">
                  <a href="/liked-products">
                    <button
                      type="button"
                      className="btn btn-green-theme me-3 d-none d-lg-inline"
                      // onClick={() => Navigate('/liked-products')}
                      aria-label="Liked Products"
                    >
                      <FontAwesomeIcon icon={["fas", "heart"]} />
                      {likedItems.length > 0 && (
                        <span className="ms-3 badge rounded-pill bg-green-theme">
                          {likedItems.length}
                        </span>
                      )}
                    </button>
                  </a>
                  <a href="/cart">
                    <button
                      type="button"
                      className="btn btn-green-theme me-3 d-none d-lg-inline"
                      // onClick={() => Navigate('/cart')}
                      aria-label="Cart"
                    >
                      <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                      {cartItems.length > 0 && (
                        <span className="ms-3 badge rounded-pill bg-green-theme">
                          {cartItems.length}
                        </span>
                      )}
                    </button>
                  </a>
                </div>
                <div className="d-lg-flex">
                  <LanguageSwitcher toggleDrawer={toggleDrawer} />
                  <ul className="navbar-nav mb-2 mb-lg-0 ">
                    {isLoggedIn ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="#"
                          className="nav-link dropdown-toggle text-green-theme"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={["fas", "user-circle"]}
                          />
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <Link
                              to="/user-profile"
                              onClick={() => toggleDrawer()}
                              className="dropdown-item"
                            >
                              Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="dropdown-item"
                              onClick={handleLogout}
                            >
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item dropdown">
                        <Link
                          to="#"
                          className="nav-link dropdown-toggle text-green-theme"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={["fas", "user-alt"]}
                          />
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <Link
                              to="#"
                              className="dropdown-item"
                              onClick={() => handleDropdownLoginClick()}
                            >
                              Login
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="dropdown-item"
                              onClick={() => handleDropdownRegisterClick()}
                            >
                              Sign Up
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="d-flex w-100 justify-content-between d-lg-none">
              <button
                className="navbar-toggler p-0 border-0 ms-3"
                type="button"
                onClick={toggleDrawer}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div>
                <a href="/liked-products">
                  <button
                    type="button"
                    className="btn btn-green-theme mx-2"
                    aria-label="Mobile Liked Products"
                  >
                    <FontAwesomeIcon icon={["fas", "heart"]} />
                    {likedItems.length > 0 && (
                      <span className="ms-3 badge rounded-pill bg-green-theme">
                        {likedItems.length}
                      </span>
                    )}
                  </button>
                </a>
                <a href="/cart">
                  <button
                    type="button"
                    className="btn btn-green-theme"
                    aria-label="Mobile Cart"
                  >
                    <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                    {cartItems.length > 0 && (
                      <span className="ms-3 badge rounded-pill bg-green-theme">
                        {cartItems.length}
                      </span>
                    )}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <LoginModel
        handleRegisterClick={handleRegisterClick}
        showLoginModal={showLoginModal}
        handleCloseModal={() => setShowLoginModal(false)}
      />
      <RegisterModel
        handleLoginClick={handleLoginClick}
        showRegisterModal={showRegisterModal}
        handleCloseModal={() => setShowRegisterModal(false)}
      />
    </>
  );
}

export default Header;
