import React, { createContext, useState } from 'react';
import { showMyOrders, showOrderHistory, repeatOrder,  cancelOrder as fetchOrderCancel } from '../apiServices/OrderService';

export const MyOrderContext = createContext();

export const MyOrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [oldOrders, setOldOrders] = useState([]);
  const [repeatOrders, setRepeatOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch orders
  const fetchMyOrders = async () => {
    setLoading(true);
    setError(null);

    try {
      const data = await showMyOrders();
      setOrders(data.orders);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch orders
  const orderHistory = async () => {
    setLoading(true);
    setError(null);

    try {
      const data = await showOrderHistory();
      setOldOrders(data.orders);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to cancel an order
  const cancelOrder = async (orderId) => {
    setLoading(true);
    setError(null);

    try {
      await fetchOrderCancel(orderId);
      // Update orders state by removing the canceled order
      setOrders((prevOrders) => prevOrders.filter(order => order.id !== orderId));
    } catch (err) {
      console.error("Error canceling order:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to cancel an order
  const handleRepeatOrder = async (orderId) => {
    setLoading(true);
    setError(null);

    try {
      await repeatOrder(orderId);
      // Update orders state by removing the canceled order
      setRepeatOrders((prevOrders) => prevOrders.filter(order => order.id !== orderId));
    } catch (err) {
      console.error("Error Repeating order:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MyOrderContext.Provider value={{ orders, loading, error, fetchMyOrders, orderHistory, handleRepeatOrder, oldOrders, repeatOrders, cancelOrder }}>
      {children}
    </MyOrderContext.Provider>
  );
};

export default MyOrderProvider;
