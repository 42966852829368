// src/context/UserProfileContext.js
import React, { createContext, useState, useEffect } from "react";
import { fetchUserData, updateProfile, uploadAttachment } from "../apiServices/userDataService"; // Import the service functions

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState({
    profileImage: null,
    profileImageUrl: "",
    firstname: "",
    lastname: "",
    email: "",
    contact: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Fetch user data from the API
  const loadUserData = async () => {
    setLoading(true);
    try {
      const userData = await fetchUserData(); // Call API function
      setUserProfile({
        profileImage: null,
        profileImageUrl: userData.avatar?.original,
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        contact: userData.contact,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data.");
    } finally {
      setLoading(false);
    }
  };

  // Update user profile
  const handleUpdateProfile = async (updatedData) => {
    setLoading(true);
    setError(null);
  
    try {
      let newProfileImageUrl = userProfile.profileImageUrl; // Keep the existing image URL by default
  
      // If the profile image has been changed, upload the new image
      if (updatedData.profileImage) {
        const imageData = await uploadAttachment(updatedData.profileImage); // Upload new image
        newProfileImageUrl = imageData.original; // Get new image URL from the upload response
      }
  
      const response = await updateProfile(updatedData); // Update profile with new data
  
      if (response.success) {
        setUserProfile((prevState) => ({
          ...prevState,
          profileImageUrl: newProfileImageUrl, // Update only if image changed
          firstname: response.customer.firstname,
          lastname: response.customer.lastname,
          email: response.customer.email,
          contact: response.customer.contact,
        }));
        setError(null);
      } else {
        setError("Profile update failed. Please try again.");
      }
    } catch (error) {
      setError("Error updating profile. Please check your input.");
    } finally {
      setLoading(false);
    }
  };  

  // Check if the user is logged in (based on token presence)
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // Fetch user data once after login
  useEffect(() => {
    if (isLoggedIn) {
      loadUserData();
    }
  }, [isLoggedIn]);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        loading,
        error,
        loadUserData,
        handleUpdateProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContext;
