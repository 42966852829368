// ContextProvider.js
import React from "react";
import { CartProvider } from "../CartContext";
import { LikedProductProvider } from "../LikedProductContext";
import { ProductProvider } from "../ProductContext";
import PlaceOrderProvider from "../PlaceOrderContext";
import { AddressProvider } from "../AddressContext";
import { UserProfileProvider } from "../UserProfileContext";
import MyOrderProvider from "../MyOrderContext";
import { CategoryProvider } from "../CategoryContext";
import { ContactUsProvider } from "../ContextUsContext";
import { AuthProvider } from "../AuthContext";
import { SearchProvider } from "../SearchContext";
import { LanguageProvider } from "../LanguageContext";

export const ContextProvider = ({ children }) => {
  return (
    <LanguageProvider>
      <CategoryProvider>
        <MyOrderProvider>
          <UserProfileProvider>
            <AddressProvider>
              <CartProvider>
                <PlaceOrderProvider>
                  <ProductProvider>
                    <LikedProductProvider>
                      <AuthProvider>
                        <ContactUsProvider>
                          <SearchProvider>{children}</SearchProvider>
                        </ContactUsProvider>
                      </AuthProvider>
                    </LikedProductProvider>
                  </ProductProvider>
                </PlaceOrderProvider>
              </CartProvider>
            </AddressProvider>
          </UserProfileProvider>
        </MyOrderProvider>
      </CategoryProvider>
    </LanguageProvider>
  );
};
