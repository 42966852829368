// SiteLogo.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import defaultLogo from "./img/Logo-image-removebg-preview.png";
import { Link } from "react-router-dom";
import { API_ENDPOINTS } from "./ApiEndPoints";

const SiteLogo = ({toggleDrawer}) => {
  const [logoUrl, setLogoUrl] = useState("");
  const [siteTitle, setSiteTitle] = useState("");

  const siteLogoUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SITE_LOGO}`;

  const fetchLogo = async () => {
    try {
      const response = await axios.get(siteLogoUrl);
      // console.log(response.data);
      setLogoUrl(response.data.options.logo.thumbnail);
      setSiteTitle(response.data.options.title);
      document.title = response.data.options.title; // Set the document title dynamically

      //metaDescription
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          response.data.options.seo.metaTitle
        ); // Update content dynamically
      }
      
    } catch (error) {
      console.error("Error fetching logo:", error);
    }
  };

  useEffect(() => {
    fetchLogo();
  }, []);

  return (
    <div className="logo-size">
      <Link to="/">
        {logoUrl ? (
          <img className="w-100" src={logoUrl} alt="Site Logo" onClick={() => toggleDrawer()} />
        ) : (
          <img className="w-100" src={defaultLogo} alt="Default Logo" onClick={() => toggleDrawer()} /> // Fallback logo
        )}
      </Link>
    </div>
  );
};

export default SiteLogo;
