import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { fetchProducts as fetchProductsFromService } from "../apiServices/productService";
import { useLanguage } from "./LanguageContext";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useLanguage(); // Get current language

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchProductsFromService(language);
      setProducts(data);
    } catch (err) {
      console.error("Error fetching products:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [language]); // Depend on language so it updates on change

  useEffect(() => {
    fetchProducts(); // Fetch products whenever language changes
  }, [fetchProducts]);

  return (
    <ProductContext.Provider
      value={{ products, setProducts, loading, error, fetchProducts }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProducts = () => useContext(ProductContext);
