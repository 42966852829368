import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../Context/AuthContext';
import { ClipLoader } from 'react-spinners';
import { API_ENDPOINTS } from '../../ApiEndPoints';
import UserProfileContext from '../../Context/UserProfileContext';
import { MyOrderContext } from '../../Context/MyOrderContext';

const LoginModel = ({ showLoginModal, handleCloseModal, handleRegisterClick }) => {
    const {loadUserData} = useContext(UserProfileContext);
    const {fetchMyOrders, orderHistory} = useContext(MyOrderContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { login } = useAuth(); // Get the login function from AuthContext
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const Navigate = useNavigate();
    const location = useLocation();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true); // Set loading to true immediately
    
        // Wait for 6 seconds before proceeding with the login request
        await new Promise(resolve => setTimeout(resolve, 1500));
        const loginUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.LOGIN}`;

        try {
            const response = await axios.post(loginUrl, {
                email,
                password,
            });
    
            if (response.status === 200) {
                const token = response.data.token;  // Get the token from the response
                localStorage.setItem("token", token);
                enqueueSnackbar("Login successful!", { variant: 'success', autoHideDuration: 2000 });
                login(); // Call the login function from AuthContext
                handleCloseModal();
                setEmail("");
                setPassword("");

                // // Fetch user data after login is successful
                await loadUserData();
                await fetchMyOrders();
                await orderHistory();
    
                // Redirect to the intended page or to the homepage
                const redirectTo = location.state?.from || "/"; // Redirect to the previous page or home
                Navigate(redirectTo); // Use navigate to go to the desired path
            } else {
                enqueueSnackbar('Login failed. Please check your credentials.', { variant: 'error', autoHideDuration: 2000 });
            }
        } catch (error) {
            if (error.response) {
                console.error("Server error:", error.response.data);
                setError('Login failed. Please check your credentials.');
                enqueueSnackbar('Login failed. Please check your credentials.', { variant: 'error', autoHideDuration: 2000 });
            } else if (error.request) {
                console.error("Network error:", error.request);
                enqueueSnackbar('Network error. Please try again later.', { variant: 'error', autoHideDuration: 2000 });
            } else {
                console.error("Error:", error.message);
                enqueueSnackbar('An unexpected error occurred. Please try again.', { variant: 'error', autoHideDuration: 2000 });
            }
        } finally {
            setLoading(false); // Ensure loading is set to false after the process
        }
    };    

    if (!showLoginModal) return null;

    return (
        <>
            {/* Background Overlay */}
            <div className="overlay-custom" onClick={handleCloseModal}></div>
            <div className="modal fade show" tabIndex="-1" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered modal-lg justify-content-center">
                    <div className="modal-content p-0">
                        <div className="modal-header px-md-4">
                            <h5 className="modal-title">{t("Login")}</h5>
                            <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                        </div>
                        <div className="modal-body px-md-4 col-md-12">
                            <form className='text-start' onSubmit={handleLoginSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">{t("Email")}</label>
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder={t("Enter-your-email")}
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="mb-3 position-relative">
                                        <label htmlFor="password" className="form-label">
                                            {t("Password")}
                                        </label>
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            placeholder={t("Enter-your-Password")}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <span
                                            className="position-absolute top-70 end-0 translate-middle-y me-3"
                                            style={{ cursor: "pointer" }}
                                            onClick={togglePasswordVisibility}
                                        >
                                            {passwordVisible ? (
                                                <FontAwesomeIcon icon={faEyeSlash} />
                                            ) : (
                                                <FontAwesomeIcon icon={faEye} />
                                            )}
                                        </span>
                                    </div>
                                </div>

                                {/* Error message */}
                                {error && <div className="alert alert-danger">{error}</div>}

                                <div className="form-check mb-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="rememberMe"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="rememberMe">{t("Remember me")}</label>
                                </div>

                                <div className="form-group mt-3 col-3">
                                    <button type="submit" className="btn btn-green-theme" disabled={loading}>
                                        {loading ? <div><ClipLoader color={"#000"} loading={loading} size={24} /></div>
                                                 : t("Login")}
                                    </button>
                                </div>
                            </form>

                            {/* Register Link */}
                            <div className="mt-3 text-center">
                                <p>
                                    {t("Don't have an account?")}{" "}
                                    <Link to="#" onClick={handleRegisterClick}>
                                        {t("Register")}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginModel;
