import React, { createContext, useState, useContext, useRef } from 'react';

const CategoryContext = createContext();

export function useCategory() {
  return useContext(CategoryContext);
}

export function CategoryProvider({ children }) {
  const productListRef = useRef(null); // Reference to Product List section
  const [selectedCategory, setSelectedCategory] = useState("All Products");

  // Scroll to the Product List section with an offset
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (productListRef.current) {
      const offset = 100; // Desired offset (100px from the top)
      const elementPosition = productListRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  return (
    <CategoryContext.Provider value={{ selectedCategory, handleCategoryClick, productListRef }}>
      {children}
    </CategoryContext.Provider>
  );
}
