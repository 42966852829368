// src/template/NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="notfound-wrapper">
      <div className="notfound-animation">
        {/* Modern SVG Animation */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 600 300"
          className="notfound-svg"
        >
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            className="notfound-text"
            fill="transparent"
          >
            404
          </text>
        </svg>
      </div>
      <h1 className="notfound-title">Oops! Page Not Found</h1>
      <p className="notfound-description">
        Sorry, the page you were looking for doesn’t exist or has been moved.
      </p>
      <button className="notfound-button" onClick={handleGoHome}>
        Return to Home
      </button>
    </div>
  );
};

export default NotFound;
