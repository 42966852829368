export const API_ENDPOINTS = {
  BANNER: "/userbanner",
  SITE_LOGO: "/settings?language=en",
  SETTINGS: "/settings",
  LOGIN: "/login",
  REGISTER: "/userregister",
  VERIFY_OTP: "/verify-otp",
  ATTACHMENTS: "/attachments",
  CHANGE_PASSWORD: "/user/change-password",
  // PRODUCTS: '/products?searchJoin=and&limit=20&page=1&orderBy=createdAt&sortedBy=desc&language=',
  PRODUCTS:
    "/webproducts?searchJoin=and&limit=20&page=1&orderBy=createdAt&sortedBy=desc&",
  SHOW_CART: "/show-cart",
  ADD_TO_CART: "/add-to-cart",
  REMOVE_FROM_CART: "/delete-from-cart",
  LIKED_PRODUCT: "/add-to-wishlist",
  SHOW_WISHLIST: "/show-wishlist",
  REMOVE_LIKED_PRODUCT: "/delete-from-wishlist",
  ADDRESS: "/address",
  SHOW_ADDRESS: "/address-list",
  DELETE_ADDRESS: "/address",
  UPDATE_ADDRESS: "/address",
  USER_DATA: "/user/profile",
  UPDATE_PROFILE: "/user/update-profile",
  PLACE_ORDER: "/place-order",
  SHOW_MY_ORDER: "/pending-order-list",
  SHOW_ORDER_HISTORY: "/complete-order-list",
  CANCLE_ORDER: "/cancel-order",
  REPEAT_ORDER: "/reorder",
  CONTACT_US: "/contact",
};
